
import { call, put, takeLatest } from 'redux-saga/effects'
import { saveAs } from 'file-saver'

import * as ACTIONS from './actions'
import * as CONSTANTS from './constants'

import {
    SettlementMatterViewModel,
    ActionstepDocument,
    SettlementMatter
} from 'utils/wcaApiTypes'
import {
    GeneratePDF,
    SavePDF,
    GetSettlementMatter,
    SaveSettlementMatter,
    DeleteSettlementMatter
} from 'containers/calculators/settlement/redux/actionTypes';
import { rawPost, postRequest, getRequest, deleteRequest } from 'utils/request'
import axios from 'utils/axios'

// by default, axios would call the json stringify function that would convert any date into utc date
// however, this will cause issues with the pdf generation where datetime is [x date]-00:00, which when converted into utc would be the previous day
// therefore the date transformer is needed to force preserve that locale date for correct pdf date generation
// the saga.ts file in special meter reading also copy this function
function dateTransformer(data: any): any {
    if (data instanceof Date) {
        // do your specific formatting here
        return data.toLocaleString("en-US");
    }
    if (Array.isArray(data)) {
        return data.map(val => dateTransformer(val))
    }
    if (typeof data === "object" && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([key, val]) =>
            [key, dateTransformer(val)]))
    }
    return data
}

async function postAndSaveFile(url: string, params: any) {
    let response = await rawPost(url, params, { responseType: 'blob', transformRequest: [dateTransformer].concat(axios.defaults.transformRequest ?? []) });

    // Log somewhat to show that the browser actually exposes the custom HTTP header
    const currentDate = new Date();
    const dateFormat = currentDate.getDate() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getFullYear() + "-" + Date.now();
    const fileName = params.matterId + "_" + (params.settlementData.additionalInfo?.["statementType"] ?? "None") + "_" + dateFormat + ".pdf";

    // Let the user save the file.
    saveAs(response.data, fileName);
}

export function* generatePDF(action: GeneratePDF) {
    try {
        yield call(postAndSaveFile, "/api/settlement-calculator/generate-pdf", action.params);

        yield put(ACTIONS.generatePDFSuccess('success'));
    } catch (error) {
        yield put(ACTIONS.generatePDFFailed(error?.response?.data));
    }
}

export function* generateStatementOfAccount(action: GeneratePDF) {
    try {
        yield call(postAndSaveFile, "/api/settlement-calculator/generate-statement-of-account", action.params);

        yield put(ACTIONS.generateStatementOfAccountSuccess('success'));
    } catch (error) {
        yield put(ACTIONS.generateStatementOfAccountFailed(error?.response?.data));
    }
}

export function* generateWaterUsagePDF(action: GeneratePDF) {
    try {
        yield call(postAndSaveFile, "/api/settlement-calculator/water-usage/generate-pdf", action.params);

        yield put(ACTIONS.generatePDFSuccess('success'));
    } catch (error) {
        yield put(ACTIONS.generatePDFFailed(error?.response?.data));
    }
}

export function* savePDF(action: SavePDF) {
    try {
        const response = yield call(postRequest, "/api/settlement-calculator/save-pdf", action.params);

        const data: ActionstepDocument = response as ActionstepDocument;

        yield put(ACTIONS.savePDFSuccess(data));
    } catch (error) {
        yield put(ACTIONS.savePDFFailed(error?.response?.data));
    }
}

export function* getSettlementMatter(action: GetSettlementMatter) {
    try {
        const response = yield call(getRequest, "/api/settlement-calculator/settlement-matter/" + action.params.orgKey + "/" + action.params.matterId);

        const data: SettlementMatterViewModel = response as SettlementMatterViewModel;

        yield put(ACTIONS.getSettlementMatterSuccess(data));

    } catch (error) {
        yield put(ACTIONS.getSettlementMatterFailed(error?.response?.data));
    }
}

export function* saveSettlementMatter(action: SaveSettlementMatter) {
    try {
        const response = yield call(postRequest, "/api/settlement-calculator/settlement-matter/", action.params);
        const data: SettlementMatter = response as SettlementMatter;

        yield put(ACTIONS.saveSettlementMatterSuccess(data));
    } catch (error) {
        yield put(ACTIONS.saveSettlementMatterFailed(error?.response?.data));
    }
}

export function* deleteSettlementMatter(action: DeleteSettlementMatter) {
    try {
        const { data } = yield call(deleteRequest, "/api/settlement-calculator/settlement-matter/" + action.params.orgKey + "/" + action.params.matterId);

        yield put(ACTIONS.deleteSettlementMatterSuccess(data));
    } catch (error) {
        yield put(ACTIONS.deleteSettlementMatterFailed(error?.response?.data));
    }
}

export default function* settlementInfoSaga() {
    yield takeLatest(CONSTANTS.GENERATE_PDF_REQUESTED, generatePDF);
    yield takeLatest(CONSTANTS.GENERATE_STATEMENT_OF_ACCOUNT_REQUESTED, generateStatementOfAccount);
    yield takeLatest(CONSTANTS.GENERATE_WATER_USAGE_PDF_REQUESTED, generateWaterUsagePDF);
    yield takeLatest(CONSTANTS.SAVE_PDF_REQUESTED, savePDF);
    yield takeLatest(CONSTANTS.GET_SETTLEMENT_MATTER_REQUESTED, getSettlementMatter);
    yield takeLatest(CONSTANTS.SAVE_SETTLEMENT_MATTER_REQUESTED, saveSettlementMatter);
    yield takeLatest(CONSTANTS.DELETE_SETTLEMENT_MATTER_REQUESTED, deleteSettlementMatter);
}